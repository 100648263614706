import * as checkoutActions from 'containers/Checkout/actions'
import * as checkoutSelectors from 'containers/Checkout/selectors'
import * as globalActions from 'containers/Landers/actions'
import * as globalSelectors from 'containers/Landers/selectors'
import * as modalActions from 'containers/Modals/actions'
import config from 'global-config'
import { get, isEmpty, set, cloneDeep, find } from 'lodash'
import { call, put, select, takeLatest, delay, take } from 'redux-saga/effects'
import { showError } from 'utils/notification'
import { getAccessToken } from 'utils/request'
import request from 'utils/request'

import * as actions from './actions'
import * as constants from './constants'
import { MEMBERSHIP_CARD_TYPE, MEMBERSHIP_CARD_PACKAGE } from 'global-constant'
import { trackEvent, trackingEvent } from 'utils/firebase'
import { MEMBERSHIP_CARD_TYPES } from 'containers/Modals/screens/MembershipApply/constants'
import replace from 'lodash/replace'
import {
  onShowLoginWithMayaCCSuccess,
  onShowMayaRenew,
  setMembershipExpiryData,
  setShowMayaRenew,
  setShowMembershipLinkFormModal,
} from 'containers/Modals/slices/renewMaya/slice'
import { makeSelectMembershipProducts } from './selectors'
import { GET_USER_INFO_SUCCESS } from 'containers/Landers/constants'
import { getStoreView } from 'utils/request'
import { req } from 'utils/req'
import { ga4TrackAddToCartMC } from 'utils/ga4'
import { isEnableBusiness } from 'config/feature'
import { onShowBizMcModal } from 'containers/Modals/slices/modals/slice'
import { ctEvent, ctEventPush } from 'utils/clevertap/ctEvent'

const __DEV__ = process.env.NODE_ENV !== 'production'

function* onLoadMembershipProducts() {
  const requestURL = `${config.apiUrl}/membership-products`
  yield put(globalActions.showLoader())

  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    })

    let membershipProducts = res

    const businessMembership = res.filter(
      (product) => product.type === MEMBERSHIP_CARD_PACKAGE.BUSINESS_PACKAGE,
    )

    if (businessMembership.length > 0) {
      // for now we will just set the final_price
      yield put(
        actions.setBusinessMembership({
          finalPrice: businessMembership[0]?.final_price || 0,
        }),
      )
    }

    if (!isEnableBusiness) {
      membershipProducts = res.filter(
        (product) => product.type !== MEMBERSHIP_CARD_PACKAGE.BUSINESS_PACKAGE,
      )
    }

    yield put(actions.loadMembershipProductsSuccess(membershipProducts))
    const currentUser = yield select((state) =>
      globalSelectors.makeSelectCurrentUser()(state),
    )
    if (getAccessToken()) {
      const code = get(
        currentUser,
        'extension_attributes.membership_info.code',
        '',
      )
      const packageValue = get(
        currentUser,
        'extension_attributes.membership_info.package',
        null,
      )

      if (code) {
        const getMembershipInfo = (state) =>
          makeSelectMembershipProducts()(state)
        const membershipProducts = yield select(getMembershipInfo)
        const filteredMembershipData = find(
          membershipProducts,
          (val) => val.package === packageValue,
        )
        if (filteredMembershipData) {
          yield put(
            actions.verifyPrimaryMembership({
              ...currentUser?.extension_attributes,
              cardType: 'buy-new',
              membership: filteredMembershipData,
              isShowModal: false,
            }),
          )
        }
      }
    }

    yield put(globalActions.hideLoader())
  } catch (err) {
    if (__DEV__) {
      console.log(err.json())
    }
    yield put(globalActions.hideLoader())
    yield put(actions.loadMembershipProductsFailed(err))
  }
}

function* onRenewMembership(action) {
  // prettier-ignore
  const requestURL = `${config.lambdaUrl}/stores/${getStoreView()}/carts/mine/items`
  yield put(globalActions.showLoader())

  yield put(globalActions.showLoader())

  const cartId = yield select((state) =>
    checkoutSelectors.makeSelectCartId()(state),
  )
  const { renew_price, code, name, trigger_link_for_renew, is_expired_renew } =
    action.requestData

  let membershipInfo = {
    type: 'renew',
    card_info: {
      code: code,
      name: name,
    },
  }
  if (get(action.requestData, 'maya_applied', false)) {
    membershipInfo = {
      type: 'renew',
      card_info: action.requestData,
    }
  }
  const membershipRequest = {
    cart_item: {
      quote_id: cartId,
      sku: action.requestData.package,
      product_type: 'customer_membership',
      qty: 1,
      extension_attributes: {
        custom_price: renew_price,
        membership_info: JSON.stringify(membershipInfo),
      },
    },
  }

  try {
    const res = yield call(req, requestURL, {
      method: 'POST',
      data: membershipRequest,
    })
    const source = 'Apply Membership'
    const element = 'Renew'
    const productData = res?.data?.data
    const sourceTracking = {
      a2c_type: '',
      a2c_source: source,
      a2c_element: element || '',
      a2c_name: productData.product_name || '',
      a2c_source_detail: `${source || ''} - ${element || ''}`,
      a2c_quantity: productData.qty || 0,
    }
    const membershipProducts = yield select((state) =>
      makeSelectMembershipProducts()(state),
    )
    const membership = membershipProducts.find(
      (product) => product.package === productData?.sku,
    )
    ctEventPush(ctEvent.applyMembershipCard, {
      sku_id: productData?.product_id,
      sku_name: productData?.name,
      sku_price_regular: membership.price,
      sku_price_special: membership.renew_price,
    })
    ga4TrackAddToCartMC(productData, sourceTracking)
    yield put(modalActions.hideMembershipModal())
    yield put(setShowMayaRenew(false))
    yield put(actions.renewMembershipSuccess(res.data))
    yield put(checkoutActions.setCartAsNeedSync('membership'))
    if (!is_expired_renew) {
      yield put(checkoutActions.syncProductThenGoCheckout())
    }

    yield put(globalActions.hideLoader())
  } catch (err) {
    if (__DEV__) {
      console.log(err.json())
    }
    yield put(globalActions.hideLoader())
    yield put(actions.renewMembershipFailed(err))
  }
}

function* onLoadMembershipBanners() {
  const requestURL = `${config.apiUrl}/landers-bannerslider-byname/membership_page_top_slider`
  yield put(globalActions.showLoader())

  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    })
    yield put(actions.loadMembershipBannerSuccess(res))
    yield put(globalActions.hideLoader())
  } catch (err) {
    if (__DEV__) {
      console.log(err.json())
    }
    yield put(globalActions.hideLoader())
    yield put(actions.loadMembershipBannerFailed(err))
  }
}

function* onApplyMembership(action) {
  let requestURL = `${
    config.lambdaUrl
  }/stores/${getStoreView()}/carts/mine/items`

  yield put(globalActions.showLoader())

  const cartId = yield select((state) =>
    checkoutSelectors.makeSelectCartId()(state),
  )

  const membershipRequest = {
    cart_item: {
      quote_id: cartId,
      ...action.requestData,
    },
  }

  if (__DEV__) {
    console.log(membershipRequest)
  }

  try {
    const res = yield call(req, requestURL, {
      method: 'POST',
      data: membershipRequest,
    })
    const source = 'Apply Membership'
    const element = ''
    const productData = res?.data?.data
    const sourceTracking = {
      a2c_type: '',
      a2c_source: source,
      a2c_element: element || '',
      a2c_name: productData?.product_name || '',
      a2c_source_detail: `${source || ''} - ${element || ''}`,
      a2c_quantity: productData?.qty || 0,
    }
    const membershipProducts = yield select((state) =>
      makeSelectMembershipProducts()(state),
    )
    const membership = membershipProducts.find(
      (product) => product.package === productData?.sku,
    )
    ctEventPush(ctEvent.applyMembershipCard, {
      sku_id: productData?.product_id,
      sku_name: productData?.name,
      sku_price_regular: membership.price,
      sku_price_special: membership.final_price,
    })
    ga4TrackAddToCartMC(productData, sourceTracking)
    yield put(globalActions.hideLoader())
    yield put(actions.applyMembershipSuccess(res.data))
    yield put(checkoutActions.setCartAsNeedSync('membership'))
    yield put(checkoutActions.syncProductThenGoCheckout())
    yield put(modalActions.hideMembershipModal())
  } catch (err) {
    if (__DEV__) {
      console.log(err.json())
    }
    showError('Add new membership to cart failed')
    yield put(modalActions.hideMembershipModal())
    yield put(globalActions.hideLoader())
    yield put(actions.applyMembershipFailed(err))
  }
}

function* onVerifyMembership(action) {
  const {
    card_number,
    name,
    card_type, // this is not the real card type it should be action type (extend, renew)
    trigger_link_for_renew,
    isMayaRenew = false,
    mayaCreditCardInfo = {},
  } = action.requestData
  const requestURL = `${config.apiUrl}/verify-membership/${card_type}`

  yield put(globalActions.showLoader())

  const cartId = yield select((state) =>
    checkoutSelectors.makeSelectCartId()(state),
  )

  const membershipRequest = {
    cartId: cartId,
    membership_card: {
      card_number,
      name,
    },
  }

  if (trigger_link_for_renew) {
    set(membershipRequest, 'membership_card.trigger_link_for_renew', 1)
  }

  if (__DEV__) {
    console.log(membershipRequest)
  }

  try {
    const validateMembership = yield call(request, requestURL, {
      method: 'POST',
      body: JSON.stringify(membershipRequest),
    })

    const {
      extended_members,
      membership_info,
      extend_times_remaining,
      message,
    } = validateMembership

    // Additinal Validations LAN-7476
    // Verify if card is biz Card
    if (validateMembership?.message_code === 'business_detected') {
      yield put(globalActions.hideLoader())
      yield put(modalActions.hideMembershipModal())
      yield put(onShowBizMcModal())
      return
    }

    // Verify for extension card
    if (card_type === 'extend') {
      if (get(membership_info, 'code')) {
        const cardType =
          membership_info.type === 'PREMIUM'
            ? MEMBERSHIP_CARD_TYPES.Premium
            : MEMBERSHIP_CARD_TYPES.Business

        yield put(
          modalActions.showMembershipModal({
            isExtend: true,
            isPremium:
              membership_info.package === MEMBERSHIP_CARD_TYPE.PREMIUM_TYPE,
            ...membership_info,
            timesRemaining: extend_times_remaining,
            extendedMembers: extended_members,
            cardType,
          }),
        )
      } else {
        showError(message)
      }
    }

    // Verify for renew card
    if (card_type === 'renew') {
      if (get(membership_info, 'code')) {
        if (isMayaRenew) {
          yield put(onShowMayaRenew())
        }

        if (!isMayaRenew) {
          let new_membership_info = cloneDeep(membership_info)
          // check if maya cc form is ticked
          if (mayaCreditCardInfo?.region_name) {
            const memberhispProduct = yield select((state) =>
              makeSelectMembershipProducts()(state),
            )

            let filterMembershipProduct = memberhispProduct.filter(
              (val) => val.package === 'PREMIUM_WITH_LANDERS_CCARD',
            )

            if (filterMembershipProduct.length > 0) {
              filterMembershipProduct = filterMembershipProduct[0]
            }
            new_membership_info = {
              ...mayaCreditCardInfo,
              code: membership_info?.code,
              expired_date: membership_info?.expired_date,
              type_membership: membership_info?.expired_date,
              package: filterMembershipProduct?.package,
              price: filterMembershipProduct?.price,
              promo_custom_stamp: filterMembershipProduct?.promo_custom_stamp,
              promo_end_date: filterMembershipProduct?.promo_end_date,
              promo_start_date: filterMembershipProduct?.promo_start_date,
              renew_price: filterMembershipProduct?.renew_price,
              type: filterMembershipProduct?.type,
              extend_price: filterMembershipProduct?.extend_price,
              extension_renew_price:
                filterMembershipProduct?.extension_renew_price,
              final_price: filterMembershipProduct?.final_price,
            }
          }
          yield put(
            actions.renewMembership({
              ...new_membership_info,
              trigger_link_for_renew,
            }),
          )
        }
      } else {
        showError(validateMembership.message)
      }
    }

    yield put(globalActions.hideLoader())
    yield put(actions.verifyMembershipSuccess(validateMembership))
  } catch (err) {
    if (__DEV__) {
      console.log(err.json())
    }
    if (err.status === 404 && card_type === 'extend') {
      showError(`Membership code ${card_number} does not exists.`)
    }
    yield put(globalActions.hideLoader())
    yield put(actions.verifyMembershipFailed(err))
  }
}

function* onGetMembershipInfo(action) {
  const requestURL = `${config.apiUrl}/membership/mine`

  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    })
    yield put(actions.setMembershipInfo(res))
  } catch (err) {
    yield put(globalActions.hideLoader())
  }
}

function* onLinkMembership(action) {
  const {
    card_number,
    name,
    card_type,
    isModalDisplay = false,
  } = action.requestData
  let requestURL = `${config.apiUrl}/${card_type}-membership/mine`

  yield put(globalActions.showLoader())

  const membershipRequest = {
    membership_card: {
      card_number: replace(card_number, /-/g, ''),
      name,
    },
  }

  if (__DEV__) {
    console.log(membershipRequest)
  }

  try {
    const res = yield call(request, requestURL, {
      method: 'POST',
      body: JSON.stringify(membershipRequest),
    })

    if (res.error) {
      trackEvent(trackingEvent.linkMembership, {
        link_membership_status: 'failed',
      })

      const showChat = get(res, 'message', '').includes(
        'issue was encountered while linking your Membership Card to your account',
      )
      yield put(modalActions.showSupportMessage(showChat))
      yield put(actions.setLinkMembershipSubmitting(false))

      if (showChat === false) {
        yield put(actions.linkMembershipFailed(res.message))
      }
    } else {
      if (res.is_expired) {
        yield put(
          globalActions.getUserInfo({
            membershipRequest: {
              renew_price: '',
              code: '',
              name: '',
              is_expired_renew: true,
            },
          }),
        )
        yield put(actions.linkMembershipSuccess(res))
      } else {
        trackEvent(trackingEvent.linkMembership, {
          link_membership_status: 'successful',
        })
        // yield put(globalActions.getUserInfo());
        yield put(actions.linkMembershipSuccess(res))
      }
      // TODO: update later, this logic is not good
      if (card_type !== 'unlink') {
        yield put(globalActions.showLoader())
        yield delay(1500)
        yield put(checkoutActions.getCartInfo())
      }
    }
    if (!isModalDisplay) {
      yield put(globalActions.hideLoader())
    }
    if (!res.error) {
      yield delay(1000)
      if (isModalDisplay) {
        yield put(globalActions.hideLoader())
        yield put(
          globalActions.getUserInfo({
            needValidateMembership: false,
            needUpdateID: true,
          }),
        )
        yield take(GET_USER_INFO_SUCCESS)

        yield put(setShowMembershipLinkFormModal(false))

        yield put(
          onShowLoginWithMayaCCSuccess({
            title: 'LINKING SUCCESSFUL',
            body: [
              'Your membership card has been successfully',
              'linked. You may now proceed on signing up for',
              'Landers Cashback Everywhere Credit Card.',
            ],
          }),
        )
        return true
      }
      window.location.reload()
    }
  } catch (err) {
    if (__DEV__) {
      console.log(err.json())
    }

    const errorJson = yield err.json()
    const errorMessage = errorJson.message.replace(
      '%code',
      card_number.toString(),
    )
    yield put(actions.linkMembershipFailed(errorMessage))
  } finally {
    yield put(globalActions.hideLoader())
  }
}

function* onVerifyPrimaryMembership(action) {
  const { payload } = action
  const { cardType, isShowModal = true } = payload

  const requestURL = `${config.apiUrl}/verify-membership/${cardType}`
  const membershipRequest = {
    cartId: 0,
    membership_card: {
      card_number: '',
      name: '',
      email: '',
    },
  }
  yield put(globalActions.showLoader())
  try {
    const res = yield call(request, requestURL, {
      method: 'POST',
      body: JSON.stringify(membershipRequest),
    })
    if (isShowModal) {
      if (!isEmpty(res?.primary_membership_info)) {
        yield put(
          modalActions.showNewMCExpiredModal({
            ...payload,
            primary_membership_info: res?.primary_membership_info,
          }),
        )
        yield put(
          actions.verifyExtensionMembershipSuccess(
            res?.primary_membership_info,
          ),
        )
      } else {
        yield put(modalActions.showNewMCExpiredModal({ ...payload }))
      }
    }
    yield put(globalActions.hideLoader())
    yield put(
      actions.verifyExtensionMembershipSuccess(res?.primary_membership_info),
    )
    yield put(actions.verifyPrimaryMembershipSuccess(res))
  } catch (err) {
    yield put(globalActions.hideLoader())
    yield put(actions.verifyExtensionMembershipFailed(err))
    yield put(actions.verifyPrimaryMembershipFailed(err))
  }
}

function* onGetMembershipExpiry({ payload }) {
  const requestURL = `${config.apiUrl}/membership-expiry-prompt`
  const requestData = {
    code: payload?.extension_attributes?.membership_info?.code,
  }

  if (!requestData?.code) {
    return true
  }

  const response = yield call(request, requestURL, {
    method: 'POST',
    body: JSON.stringify(requestData),
  })

  yield put(setMembershipExpiryData(response))

  const expiredDate = response?.expiry_date

  if (response.is_expired === 1) {
    yield put(
      modalActions.setMCExpiredTooltip({
        isShow: true,
        type: constants.EXPIRED_NUDGE,
        expiredDate,
      }),
    )

    return
  }

  if (response.about_to_expire === 1) {
    const numberOfDaysRemaining = response?.remaining_days

    yield put(
      modalActions.setMCExpiredTooltip({
        isShow: response.about_to_expire === 1 ? true : false,
        type: constants.ABOUT_TO_EXPIRE_NUDGE,
        numberOfDays: numberOfDaysRemaining < 0 ? 0 : numberOfDaysRemaining,
        expiredDate,
        promoImage: response.promo_image,
        promoTitle: response.promo_title,
        promoVerbiage: response.promo_verbiage,
      }),
    )
  }
}

function* onGetGetRaffleConfigs() {
  const requestURL = `${config.apiUrl}/raffle/configs`

  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    })
    yield put(actions.setRaffleConfigs(res))
  } catch (err) {}
}

function* onGetGetRaffleFaqs() {
  const requestURL = `${config.apiUrl}/helpdesk/get-faqs?article_type=faq-raffle`

  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    })
    yield put(actions.setRaffleFaqs(res))
  } catch (err) {}
}

export default function* membershipSaga() {
  yield takeLatest(constants.APPLY_MEMBERSHIP, onApplyMembership)
  yield takeLatest(constants.MEMBERSHIP_PRODUCTS, onLoadMembershipProducts)
  yield takeLatest(constants.LOAD_MEMBERSHIP_BANNER, onLoadMembershipBanners)
  yield takeLatest(constants.VERIFY_MEMBERSHIP, onVerifyMembership)
  yield takeLatest(constants.RENEW_MEMBERSHIP, onRenewMembership)
  yield takeLatest(constants.LINK_MEMBERSHIP, onLinkMembership)
  yield takeLatest(constants.GET_MEMBERSHIP_INFO, onGetMembershipInfo)
  yield takeLatest(
    constants.VERIFY_PRIMARY_MEMBERSHIP,
    onVerifyPrimaryMembership,
  )
  yield takeLatest(constants.MEMBERSHIP_EXPIRATION, onGetMembershipExpiry)
  yield takeLatest(constants.GET_RAFFLE_CONFIGS, onGetGetRaffleConfigs)
  yield takeLatest(constants.GET_RAFFLE_FAQS, onGetGetRaffleFaqs)
}
